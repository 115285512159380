import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { get } from '@/utils/get'
import { sectionSerializer } from '@/prismic/utils/sectionSerializer'
import { linkResolver } from '@/prismic/utils/linkResolver'
import Faq from '@/templates/Faq'

const FaqPrismic = ({ data }) => {
  const { prismicFaq } = data
  const slices = get(prismicFaq, 'data.body') || []
  const sections = sectionSerializer({ slices })

  return <Faq title={prismicFaq.data.title} sections={sections} />
}

FaqPrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPrismicPreview(FaqPrismic, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
  },
])

export const query = graphql`
  query FaqByID($id: String!) {
    prismicFaq(id: { eq: $id }) {
      _previewable
      uid
      id
      type
      data {
        title
        body {
          ... on PrismicFaqDataBodyText {
            id
            slice_type
            primary {
              body_text {
                raw
              }
            }
          }
          ... on PrismicFaqDataBodyImage {
            id
            slice_type
            primary {
              image {
                fluid(maxWidth: 3000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicFaqDataBodyCta {
            id
            slice_type
            primary {
              link_label
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
            }
          }
        }
      }
    }
  }
`
