import React from 'react'
import PropTypes from 'prop-types'
import { SectionRouter } from '@/components/SectionRouter'

import { Cta } from '@/sections/Cta'
import { Text } from '@/sections/Text'
import { Image } from '@/sections/Image'
import { PageLink } from '@/sections/PageLink'
import { FaqHeader } from '@/sections/FaqHeader'

import * as styles from './Faq.styles.scss'

const Faq = ({ title, sections }) => {
  return (
    <div className={styles.Faq}>
      <div>
        <FaqHeader title={title} />
        <SectionRouter
          types={{
            text: Text,
            image: Image,
            cta: Cta,
          }}
          sections={sections}
        />
        <PageLink link={{ url: '/faq' }} label="Read more FAQs" />
      </div>
    </div>
  )
}

Faq.defaultProps = {
  sections: [],
}

Faq.propTypes = {
  title: PropTypes.node,
  sections: PropTypes.array,
}

export default Faq
